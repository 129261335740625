import type {EventParams} from './types/ga.types';

export const GoogleAnalyticsID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS || 'G-C24ZRGS56E';

// log the pageview with their URL
export const pageview = (url: string) => {
    if (typeof window === 'undefined' || !window || !window.gtag) {
        return;
    }
    try {
        window.gtag('config', GoogleAnalyticsID, {
            page_path: url,
        });
    } catch (e) {
        console.error(e);
    }
};

// log specific events happening.
export const event = ({action, params}: EventParams) => {
    if (typeof window === 'undefined' || !window || !window.gtag) {
        return;
    }
    try {
        window.gtag('event', action, params);
    } catch (e) {
        console.error(e);
    }
};
